<template>
  <div>
    <h3>Capaian Kinerja Urusan</h3>
    <br />
    <CCard>
      <CCardBody>
        <div class="row">
          <div class="col-12">
            <div v-for="(item) in items" :key="item.description">
                <b>{{ item.code }} {{ item.description }}</b><br /><br />
                <div v-for="detail in item.sub_urusan" :key="detail.description">
                  <div class="row mb-2" >
                    <div class="col-10"><b>{{ detail.code }} {{ detail.description }}</b></div>
                    <div class="col-2" style="text-align:right">
                        <button class="btn btn-primary btn-sm" @click="detail.is_detail = !detail.is_detail">
                          Detail
                        </button>
                    </div>
                  </div>
                  <div v-if="detail.is_detail">
                    <div class="table-responsive" v-for="urusan_detail in detail.sub_urusan" :key="urusan_detail.description">
                      <table class="table table-bordered" style="white-space: normal !important">
                        <tr>
                          <td style="width: 250px">{{ urusan_detail.code }} {{ urusan_detail.description }}</td>
                          <td style="text-align:center;width: 50px" v-for="tahun in tahuns" :key="tahun.tahun">
                            {{ tahun.tahun }}
                          </td>
                        </tr>
                        <tr v-for="ud in urusan_detail.urusan_detail" :key="ud.description">
                          <td>{{ ud.description }}</td>
                          <td style="text-align:center;width: 60px" v-for="tahun in tahuns" :key="tahun.tahun">
                            <input type="number" style="width:60px" :id="tahun.tahun+'_'+ud.id" :value="getValue(tahun.tahun, ud.id)" :disabled="getValue(tahun.tahun, ud.id) > 0 && user.role.is_opd == 1" />
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="mb-3">
                      <button class="btn btn-primary btn-sm m-1" @click="saveDetail(detail.sub_urusan)">
                        Simpan
                      </button>
                      <button class="btn btn-secondary btn-sm  m-1" @click="detail.is_detail = !detail.is_detail">
                        Tutup
                      </button>
                    </div>
                  </div>
                </div>
                <hr class="mp-0" />
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>


<script>
export default {
  data() {
    return {
      items: [],
      tahuns: [],
      urusan_tahuns: [],
      params: {
        sorttype: "ASC",
        sortby: "code",
        row: 5,
        page: 1,
        role_id: 0,
        keyword: "",
      },
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    getData() {
      var loading = this.$loading.show();
     
      if (this.user.role.name != "ADMIN") {
        if (this.user.role.is_opd != 0) {
          if (this.user.role_id != null) {
            this.params.role_id = this.user.role_id;
          }
        }
      }
      
      this.$store
        .dispatch("urusan/getUrusanNested", this.params)
        .then((resp) => {
          this.items = resp;
          this.getTahun();
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    getTahun() {
      var loading = this.$loading.show();
      if (this.user.role_id != null) {
        this.params.role_id = this.user.role_id;
      }
      this.$store
        .dispatch("tahun/getTahun", {
          sorttype: "ASC",
          sortby: "tahun",
          row: 100,
          page: 1,
          role_id: 0,
          keyword: "",
      })
        .then((resp) => {
          this.tahuns = resp.data.data;
          this.getUrusanTahun();
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    getUrusanTahun() {
      var loading = this.$loading.show();
      if (this.user.role_id != null) {
        this.params.role_id = this.user.role_id;
      }
      this.$store
        .dispatch("urusan/getUrusanTahun", {})
        .then((resp) => {
          this.urusan_tahuns = resp.data;
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    saveDetail(data) {
      let result = [];
      data.forEach(element => {
        element.urusan_detail.forEach(element_detail => {
          this.tahuns.forEach(element_tahun => {
            var value = document.getElementById(element_tahun.tahun+"_"+element_detail.id).value;
            result.push({
              urusan_detail_id: element_detail.id,
              tahun: element_tahun.tahun,
              value: value
            })
          }) 
        });
      });

      var loading = this.$loading.show();
      this.$store
        .dispatch("urusan/addUrusanTahun", { data: result  })
        .then((resp) => {
          this.$toast.success(resp.message);
          this.getUrusanTahun();
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    getValue(tahun, id) {
      let value = 0;
      this.urusan_tahuns.forEach(element => {
        if (element.tahun == tahun && id == element.urusan_detail_id) {
          value = element.value;
        }
      });
      return value;
    }
  },
  computed: {
  },
  mounted() {
    this.getData()
  },
};
</script>